.login {
  background-color: #f8f8f8;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.login_container {
  padding: 100px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.75);
}

.login_container > img {
  object-fit: contain;
  height: 100px;
  margin-bottom: 40px;
}

.login_container > button {
  margin-top: 50px;
  text-transform: inherit !important;
  background-color: #0a8d48 !important;
  color: white;
}
