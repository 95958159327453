.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65;
}

.chat_header {
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.chat_headerInfo {
  flex: 1;
  padding-left: 20px;
}

.chat_headerInfo > h3 {
  margin-bottom: 3px;
  font-weight: 500;
}

.chat_headerInfo > p {
  color: gray;
}
.chat_body {
  flex: 1;
  background-image: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  overflow: scroll;
}

.chat_message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.chat_receiver {
  margin-left: auto;
  background-color: #dcf8c6;
}

.chat_timestamp {
  margin-left: 10px;
  font-size: xx-small;
}

.chat_name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
}

.chat_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
}

.chat_footer > form {
  flex: 1;
  display: flex;
}

.chat_footer > form > input {
  flex: 1;
  outline-width: 0;
  border-radius: 30px;
  padding: 10px;
  border: none;
}
.chat_footer > form > button {
  display: none;
}

.chat_footer > .MuiSvgIcon-root {
  padding: 10px;
  color: gray;
}
