.sidebar_search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 39px;
  padding: 10px;
}

.sidebar_searchContainer {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.sidebar_searchContainer > .MuiSvgIcon-root {
  color: gray;
  padding: 10px;
}

.sidebar_searchContainer > input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}
