.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0.35;
}
.sidebar_header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-right: 1px solid lightgray;
}

.sidebar_headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10vw;
}

.sidebar_headerRight > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
}

.sidebar_chats {
  flex: 1;
  background-color: white;
  overflow: scroll;
}
